const formatUser = function(user) {
  let userHash = new Object();
  userHash.full_name = user["firstname"] + " " + user["lastname"];
  userHash.title = user["title"];
  userHash.email_address = user["email_address"];
  userHash.business_title = user["business_title"];
  userHash.company_name = user["company_name"];
  userHash.primary_phone = user["primary_phone"];
  userHash.address_line_1 = user["address_line_1"];
  userHash.address_line_2 = user["address_line_2"];
  userHash.city = user["city"];
  userHash.state = user["state"];
  userHash.country = user["country"];
  userHash.zip_code = user["zip_code"];
  if (user.hfa_account_number != null)
    userHash.hfa_account_number = user["hfa_account_number"];
  if (user.ipi_number != null) userHash.ipi_number = user["ipi_number"];
  if (user.secondary_phone != null)
    userHash.secondary_phone = user["secondary_phone"];
  user.certified == true
    ? (userHash.certified = "Y")
    : (userHash.certified = "N");
  user.authorized == true
    ? (userHash.authorized = "Y")
    : (userHash.authorized = "N");

  return userHash;
};

export default {
  formatUser
};
